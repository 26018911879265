/* You can add global styles to this file, and also import other style files */
$primary: #42A7DD;
$popup-text: #42A7DD;
$kendo-list-item-hover-text: #42A7DD;
$kendo-list-item-selected-text: #42A7DD;
@import "~@progress/kendo-theme-material/dist/all.scss";

/* Hide scrollbar chrome, safari, opera*/
body::-webkit-scrollbar {
    //display: none;
}

/* Hide scrollbar Firefox */
html {
    //scrollbar-width: none;
}
